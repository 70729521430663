.container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider {
    position: relative;
    width: 200px;
}

.sliderTrack,
.sliderTrackDisabled,
.sliderRange,
.sliderLeftValue,
.sliderRightValue {
    position: absolute;
}

.sliderTrack,
.sliderTrackDisabled
.sliderRange {
    border-radius: 3px;
    height: 5px;
}

.sliderTrackDisabled {
    background-color: #e8e8e8;
    width: 100%;
    z-index: 1;
}

.sliderTrack {
    background-color: #ced4da;
    width: 100%;
    z-index: 0;
}

.sliderRange {
    background-color: #e17d72;
    z-index: 1;
    height: 5px;
}

.sliderLeftValue,
.sliderRightValue {
    color: #232323;
    font-size: 12px;
    margin-top: 20px;
}

.sliderLeftValue {
    left: 6px;
}

.sliderRightValue {
    right: -4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 200px;
    outline: none;
}

.thumbLeft {
    z-index: 2;
}

.thumbRight {
    z-index: 2;
}

.thumb::-webkit-slider-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
}

.blog {
  font-family: Arial, Helvetica, sans-serif;
}
.blog h2 {
  font-weight: 700;
  font-size: xx-large;
  width: 100%;
  padding: 10px 0;
  line-height: 1.7em;
  margin: 5px 0;
}

.blog h3 {
  font-size: x-large;
  font-weight: bold;
  line-height: 1.7em;
  width: 100%;
  padding: 10px 0;
}

.blog h4 {
  font-size: large;
  font-weight: 600;
  line-height: 1.7em;
}

.blog p {
  margin: 10px 10px;
  font-size: 19px;
  line-height: 1.7em;
  letter-spacing: 0em;
  font-weight: 400;
  overflow-wrap: break-word;
}

.blog a {
  color: #e17d72;
  text-decoration: underline;
}

.blog img {
  margin: auto;
  max-width: 600px;
  max-height: 600px;
  object-fit: cover;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 05%;
}
.blog ul {
  list-style: disc;
  padding: 10px 0px;
}

.blog ul > li {
  line-height: 1.6;
  font-size: 16px;
  margin: 5px 0;
  padding-left: 10px;
  list-style-position: inside;
  line-height: 1.7em;
}

.blog ol {
  list-style: auto;
  padding: 10px 0px 10px 0px;
}

.blog ol > li {
  line-height: 1.6;
  font-size: 16px;
  margin: 5px 0;
  list-style-position: inside;
  padding-left: 10px;
  line-height: 1.7em;
}

.blog blockquote {
  margin: 5px 0;
  padding: 20px;
  background-color: #e9f3fd;
  border-left: 5px solid #80bcfd;
}

.mobile-blog {
  font-family: Arial, Helvetica, sans-serif;
}
.mobile-blog h2 {
  font-weight: 700;
  font-size: larger;
  width: 100%;
  padding: 10px 0;
  line-height: 1.7em;
  margin: 5px 0;
}

.mobile-blog h3 {
  font-size: large;
  font-weight: bold;
  line-height: 1.7em;
  width: 100%;
  padding: 5px 0;
}

.mobile-blog h4 {
  font-size: medium;
  font-weight: 600;
  line-height: 1.7em;
}

.mobile-blog p {
  margin: 10px 10px;
  font-size: 17px;
  line-height: 1.7em;
  letter-spacing: 0em;
  font-weight: 400;
  overflow-wrap: break-word;
}

.mobile-blog a {
  color: #e17d72;
  text-decoration: underline;
}

.mobile-blog img {
  margin: auto;
  max-width: 100%;
  max-height: 300px;
  object-fit: cover;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 05%;
}
.mobile-blog ul {
  list-style: disc;
  padding: 10px 0px;
}

.mobile-blog ul > li {
  line-height: 1.6;
  font-size: 16px;
  margin: 5px 0;
  padding-left: 10px;
  list-style-position: inside;
  line-height: 1.7em;
}

.mobile-blog ol {
  list-style: auto;
  padding: 10px 0px 10px 0px;
}

.mobile-blog ol > li {
  line-height: 1.6;
  font-size: 16px;
  margin: 5px 0;
  list-style-position: inside;
  padding-left: 10px;
  line-height: 1.7em;
}

.mobile-blog blockquote {
  margin: 5px 0;
  padding: 20px;
  background-color: #e9f3fd;
  border-left: 5px solid #80bcfd;
}

.ReactQuillEditor {
  font-family: Arial, Helvetica, sans-serif;
}

.ReactQuillEditor > div > div:nth-child(2) {
  min-height: 400px;
  max-height: 555px;
  border-radius: 0 0 0.75em 0.75em;
  border: solid #e8e8e8 1px !important;
  background-color: white;
}

.ReactQuillEditor > div > div:nth-child(2) > div:nth-child(1) {
  min-height: 400px;
  max-height: 555px;
  left: 0 !important;
}

.ReactQuillEditor > div > div:nth-child(2) > div {
  left: 20px !important;
}

.ReactQuillEditor > div > div:nth-child(2) > div > p {
  margin: 10px 10px;
  font-size: 19px;
  line-height: 1.7em;
  letter-spacing: 0em;
  font-weight: 400;
  overflow-wrap: break-word;
}

.ReactQuillEditor > div > div:nth-child(2) > div > ol > li {
  line-height: 1.6;
  font-size: 18px;
  margin: 5px 0;
  list-style-position: inside;
  padding-left: 10px;
  line-height: 1.7em;
}

.ReactQuillEditor > div > div:nth-child(2) > div > iframe {
  width: 100%;
  max-height: 305px;
}

.ReactQuillEditor > div > div:nth-child(2) > div > h1 {
  font-family: Microsoft JhengHei fixed, Helvetica Neue, Microsoft JhengHei, Helvetica, Arial, sans-serif;
  width: 100%;
  padding: 10px 0 10px 0;
  margin: 5px 0 5px 0;
  font-size: 2em;
}

.ReactQuillEditor > div > div:nth-child(2) > div > h2 {
  font-weight: 700;
  font-size: xx-large;
  width: 100%;
  padding: 10px 0;
  line-height: 1.7em;
  margin: 5px 0;
}

.ReactQuillEditor > div > div:nth-child(2) > div > h3 {
  font-size: x-large;
  font-weight: bold;
  line-height: 1.7em;
  width: 100%;
  padding: 10px 0;
}

.ReactQuillEditor > div > div:nth-child(2) > div > h4 {
  font-size: large;
  font-weight: 600;
  line-height: 1.7em;
}

.ReactQuillEditor > div > div:nth-child(2) > div > p > h1 {
  font-family: Microsoft JhengHei fixed, Helvetica Neue, Microsoft JhengHei, Helvetica, Arial, sans-serif;
  width: 100%;
  padding: 10px 0 10px 0;
  margin: 5px 0 5px 0;
  font-size: 2em;
}

.ReactQuillEditor > div > div:nth-child(2) > div > p > h2 {
  font-weight: 700;
  font-size: xx-large;
  width: 100%;
  padding: 10px 0;
  line-height: 1.7em;
  margin: 5px 0;
}

.ReactQuillEditor > div > div:nth-child(2) > div > p > h3 {
  font-size: x-large;
  font-weight: bold;
  line-height: 1.7em;
  width: 100%;
  padding: 10px 0;
}

.ReactQuillEditor > div > div:nth-child(2) > div > p > h4 {
  font-size: large;
  font-weight: 600;
  line-height: 1.7em;
}

.ReactQuillEditor > div > div:nth-child(2) > div > audio {
  width: 115px;
  display: inline-block;
  height: 35px;
}

.ReactQuillEditor > div > div:nth-child(2) > div > blockquote {
  margin: 5px 0;
  padding: 20px;
  background-color: #e9f3fd;
  border-left: 5px solid #80bcfd;
}

.ReactQuillEditor > div > div:nth-child(2) > div > pre {
  font-size: 14px;
  background-color: #fafafa;
  border: #e8e8e8 solid 1px;
  padding: 5px;
  line-height: 1.5;
}

.ReactQuillEditor > div > div:nth-child(2) > div > ul > li {
  line-height: 1.6;
  font-size: 18px;
  margin: 5px 0;
  padding-left: 10px;
  list-style-position: inside;
  line-height: 1.7em;
}

.ReactQuillEditor > div > div:nth-child(2) > div > ol > li {
  line-height: 1.6;
  font-size: 18px;
  margin: 5px 0;
  list-style-position: inside;
  padding-left: 10px;
  line-height: 1.7em;
}

.ReactQuillEditor > div > div:nth-child(2) > div > p > img {
  max-width: 100%;
  width: auto;
  padding: 5px;
  max-height: 450px;
  display: inline-block;
  margin: 15px auto;
}

.ReactQuillEditor > div > div:nth-child(2) > img {
  max-width: 100%;
  width: auto;
  padding: 5px;
  max-height: 450px;
  display: inline-block;
  margin: 15px auto;
}

.ReactQuillEditor > div > div:nth-child(2) > div > img {
  max-width: 100%;
  width: auto;
  padding: 5px;
  max-height: 400px;
  display: inline-block;
  margin: 15px auto;
}

.ReactQuillEditor > div > div:nth-child(2) > div > ul {
  list-style: disc;
  padding: 10px 0px;
}

.ReactQuillEditorComment {
  padding-top: 25px;
}

.ReactQuillEditorComment > div > div:nth-child(2) {
  min-height: 200px;
  max-height: 555px;
  border-radius: 0 0 0.75em 0.75em;
  border: solid #e8e8e8 1px !important;
  background-color: white;
}

.ReactQuillEditorComment > div > div:nth-child(2) > div {
  min-height: 200px;
  max-height: 555px;
}

.ReactQuillEditorComment > div > div:nth-child(2) > div > p {
  font-size: 12px;
  font-weight: 100;
  line-height: 2;
}

.ReactQuillEditorComment > div > div:nth-child(2) > div > ol > li {
  line-height: 1.6;
  font-size: 18px;
  margin: 5px 0;
  padding-left: 10px;
  list-style-position: inside;
  line-height: 1.7em;
}

.ReactQuillEditorComment > div > div:nth-child(2) > div > iframe {
  width: 100%;
  max-height: 305px;
}

.ReactQuillEditorComment > div > div:nth-child(2) > div > h1 {
  width: 100%;
  padding: 10px 0 10px 0;
  margin: 5px 0 5px 0;
  font-size: 2em;
}

.ReactQuillEditorComment > div > div:nth-child(2) > div > h2 {
  font-weight: 700;
  font-size: xx-large;
  width: 100%;
  padding: 10px 0;
  line-height: 1.7em;
  margin: 5px 0;
}

.ReactQuillEditorComment > div > div:nth-child(2) > div > h3 {
  font-size: x-large;
  font-weight: bold;
  line-height: 1.7em;
  width: 100%;
  padding: 10px 0;
}

.ReactQuillEditorComment > div > div:nth-child(2) > div > h4 {
  font-size: large;
  font-weight: 600;
  line-height: 1.7em;
}

.ReactQuillEditorComment > div > div:nth-child(2) > div > blockquote {
  margin: 5px 0;
  padding: 20px;
  background-color: #e9f3fd;
  border-left: 5px solid #80bcfd;
}

.ReactQuillEditorComment > div > div:nth-child(2) > div > pre {
  font-size: 14px;
  background-color: #fafafa;
  border: #e8e8e8 solid 1px;
  padding: 5px;
  line-height: 1.5;
}

.ReactQuillEditorComment > div > div:nth-child(2) > div > ul > li {
  line-height: 1.6;
  font-size: 18px;
  margin: 5px 0;
  padding-left: 10px;
  list-style-position: inside;
  line-height: 1.7em;
}

.ReactQuillEditorComment > div > div:nth-child(2) > div > p > img {
  max-width: 100%;
  width: auto;
  padding: 5px;
  max-height: 400px;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}

.ReactQuillEditorComment > div > div:nth-child(2) > div > img {
  max-width: 100%;
  width: auto;
  padding: 5px;
  max-height: 400px;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}

.CustomToolbar {
  width: 100%;
  height: 50px;
  border: 1px solid #ccc;
}

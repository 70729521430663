.DatePicker {
  width: 100%;
  display: block;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.UploadImageBtn {
  padding: 5px;
  margin: 5px 0;
  border-radius: 0.75em;
  width: 100%;
  border: none;
  background-color: #ffb200;
}

.ImageContainer {
  /*min-width: 40%;*/
  max-width: 120px;
  object-fit: cover;
  display: inline-table;
  margin: 5px;
  cursor: pointer;
}

.DeleteImageBtn {
  /*position: absolute;*/
  /*top: 0;*/
  /*right: 0;*/
  width: 40px;
  height: 40px;
  border: none;
  background-color: lightcoral;
  color: #fafafa;
  margin: 0 10px;
  z-index: 10;
  border-radius: 0.75em;
}

.ImageItem {
  display: inline-block;
  min-width: 180px;
  min-height: 180px;
  max-width: 180px;
  max-height: 180px;
  object-fit: contain;
  padding: 5px;
}
